@import "~antd/dist/antd.less";
@primary-color: #0a1746;
@secondary-color: rgb(117, 255, 202);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.login-container {
  width: 100%;
  height: 100%;
}
#root {
  height: 100%;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.full-width {
  width: 100%;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.ant-layout-header {
  padding: 0 15px;
}
.admin-layout .ant-layout-header {
  padding: 0 15px;
  margin-left: 200px;
}
.logo {
  height: 64px;
  color: #fff;
  margin-bottom: 10px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 5px;
}
.ant-layout {
  padding: 0px !important;
}
.ant-layout-header {
  background: #ffffff;
}
.m-r-8 {
  margin-right: 8px;
}
.p-h-5 {
  padding: 0 5px;
}
.p-v-5 {
  padding: 5px 0px;
}
.m-h-5 {
  margin: 0 5px;
}
.m-v-5 {
  margin: 5px 0px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.text-primary {
  color: @primary-color;
}
.bg-primary {
  background-color: @primary-color;
}
.site-badge-count-4 .ant-badge-count {
  background-color: #fff;
  color: #999;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}
.diamondSearchPage {
  .ant-upload-list {
    display: none;
  }
}
.text-right {
  text-align: right;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.header-row {
  height: 35px;
  background-color: #efefef;
  position: relative;
  padding: 5px 10px;
  .bottom-arrow {
    position: absolute;
    bottom: -9px;
    height: 10px;
    width: 10px;
    left: 30px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #efefef;
  }
}
.filter-row {
  .filter-diamond-box {
    margin: 5px 5px 5px 0px;
  }
}

.ant-layout-content {
  background-color: #ffffffb8 !important;
}
.df {
  display: flex !important;
}
.asc {
  align-self: center;
}

.fdc {
  flex-direction: column;
}
.fdrr {
  flex-direction: row-reverse;
}
.js {
  justify-content: flex-start;
}
.je {
  justify-content: end;
}
.jc {
  justify-content: center;
}
.jsb {
  justify-content: space-between;
}
.asb {
  justify-content: space-between;
}
.jsa {
  justify-content: space-around;
}
.asa {
  justify-content: space-around;
}
.as {
  align-items: flex-start;
}
.am {
  align-items: center;
}
.ab {
  align-items: flex-end;
}
.fwn {
  flex-wrap: nowrap;
}
.fg1 {
  flex-grow: 1;
}

//chatwoot start
.chat-card {
  padding: 10px;
  border-radius: 6px;
  height: 100%;
  // border: 1px solid #efefef;
  // background: #f5f5f5;
  .chat-search .ant-select-selector {
    border-radius: 16px;
    border: 1px solid #0a1746,
  }
  .ant-layout-header {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .ant-card-body {
    border: 1px solid #c8c3c3;
    border-radius: 10px;
    padding: 1px;
    box-shadow: 0px 2px 13px rgba(32, 47, 61, 0.13);
  }
 
  .chat-layout {
    height: ~"calc(100vh - 75px)";
    border-radius: 10px;
    .patient-siderbar {
      overflow: auto;
      border-radius: 0px 10px 10px 0px;
      background-color: #fff;
      min-width: 240px !important;
      width: 240px !important;
      border-left: 1px solid #f0f0f0;
      
      .patient-header {
        padding: 15px 15px 5px 15px;
        color: #888;
        display: flex;
        
      }
      .request-status {
        padding: 5px;
        border-radius: 5px;
        margin: 5px;
        text-align: center;
        font-weight: bold; 
        margin-left: 25px;
      }
      .patient-heading{
        font-size: 16px;
        color: #0a1746;
        margin: 2px 0 0 5px;
        font-weight: bold;
      }
      .patient-details-content{
        font-size: 14px;
        color: #4f4e4e;
        padding: 0px 15px 0px 15px;
        }
      
    }
    .chat-siderbar {
      border-radius: 10px 0px 0px 10px;
      background-color: #fff;
      min-width: 350px !important;
      width: 350px !important;
      border-right: 1px solid #f0f0f0;
      padding: 5px;
      .conversation-header {
        padding: 10px;
      }
      .ant-form-item {
        margin-bottom: 5px !important;
        label {
          font-weight: bold;
        }
      }
      .active-thread {
        background-color: #ebf5ff;
        padding: 10px !important;
        border-left: 2px solid #0a1746;
      }
      .inactive-thread {
        padding: 6px 8px !important;
      }
      .ant-list-item {
        align-items: flex-start;
      }
      .ant-list-item-meta-avatar {
        align-self: center;
      }
    }
  }
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.m-t-5 {
  margin-top: 5px;
}
.chat-list {
  ::-webkit-scrollbar {
    width: 0px;
  }
}
.chat-list {
  overflow-y: auto;
  height: ~"calc(90vh - (75px + 65px))";
}
.chat-list.scroll {
  ::-webkit-scrollbar {
    width: 8px;
  }
}
.chat-conversation-header {
  border-radius: 0px 10px 0px 0px;
  height: auto;
  .ant-list-item {
    padding: 0px;
  }
  .ant-list-item-meta-avatar {
    display: flex;
  }
  .call-button {
    webkit-transform: rotateX(180deg);
    transform: rotateY(180deg);
  }
}
@media (max-width: 768px) {
  .chat-conversation-header {
     height: auto;
   }
 }


.chat-conversation {
  .scrollable {
    overflow: auto;
    height: ~"calc(100vh - 200px)";
    padding: 20px;
    .message-card {
      margin-bottom: 10px;
      border-radius: 0px 20px 20px 20px;
      padding: 16px;
      border: 1px solid #efefef;
      // max-width: 350px;
      font-size: 16px;
      .message-time {
        color: #00000073;
        font-size: 0.75rem;
        margin-top: 8px;
      }
    }
    .card-right {
      background-color: @primary-color;
      border-radius: 20px 0px 20px 20px;
      span {
        color: #fff !important;
      }
      text-align: right;
    }
    .internal-chat-right{
      background-color: @secondary-color;
      border-radius: 20px 0px 20px 20px;
      span {
        color: @primary-color !important;
      }
      text-align: right;
    }
    .card-left {
      background-color: #fff;
      border: 1px solid #efefef;
      text-align: left;
    }
    .internal-chat-left {
      background-color: #efefef;
      border: 1px solid #efefef;
      text-align: left;
    }
    .private {
      background-color: #fff3d5;
      position: relative;
      span {
        color: #000 !important;
      }
      .lock-icon {
        position: absolute;
        top: 0px;
        left: 4px;
      }
    }
  }
}
.chat-conversation-footer {
  padding: 20px !important;
  position: relative;
  border-radius: 0px 0px 6px 0px;
  border-top: 1px solid #efefef;
  background-color: #ffffff;
  .is-private-switch.ant-switch-checked {
    .ant-switch-handle {
      left: ~"calc(100% - 18px - 2px)" !important;
    }
  }
  textarea {
    resize: none;
  }
}
.emoticons-popover {
  .ant-popover-inner-content {
    padding: 0px;
  }
}
.attached-files {
  z-index: 1;
  position: absolute;
  top: -40px;
  width: 98%;
  background: #efefef;
}
.attachment-icon {
  font-size: 24px;
}
.ant-upload-list {
  display: none;
}
.layout-fill {
  width: 100%;
  height: 100%;
}
//chatwoot start
.birthdate-tooltip {
  .ant-tooltip-inner {
    background-color: #ffffff !important;
    border-radius: 6px !important;
  }
}
